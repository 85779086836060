import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Units } from '../../redux/global/globalTypes';
import { axisClasses } from '@mui/x-charts';
import { GREY } from '../ui/Colors';


const xLabels = [
    'Sell-In',
    'Sell-Out'
];

export enum LegendColor {
    GLOBAL = "#D0DDFF",
    PART = "#A5BEFE",
    SECONDBAR = "#0C50D3"
}

export const StackedBarChart = ({ global, globalLabel, part, partLabel, secondBar, secondBarLabel }: { global: number, globalLabel: string, part: number, partLabel: string, secondBar: number, secondBarLabel: string }) => {
    return (
        <BarChart
            width={200}
            height={250}
            sx={(theme) => ({

                [`.MuiBarElement-series-l_id`]: {
                    stroke: "purple",
                },
                [`.MuiBarElement-series-r_id`]: {
                    stroke: "red",
                },
                [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                        stroke: GREY,
                        strokeWidth: 1,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                        fill: GREY,
                    },
                },
            })
            }
            series={
                [
                    { data: [part, 0,], label: `${partLabel}`, id: '2', stack: 'total', color: LegendColor.PART },
                    { data: [global, 0], label: `${globalLabel}`, id: '1', stack: 'total', color: LegendColor.GLOBAL },
                    { data: [0, secondBar], label: `${secondBarLabel}`, id: '3', stack: 'total', color: LegendColor.SECONDBAR },
                ]}
            tooltip={{
                trigger: 'none'
            }}
            xAxis={[{ data: xLabels, scaleType: 'band' }]}
            slotProps={{
                legend: {
                    hidden: true,
                },


            }}
        />)
}