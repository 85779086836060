import React from "react"
import { Card, CardContent, Grid } from "@mui/material"
import { BLACK } from "../ui/Colors"
import { PrimaryText, SecondaryText, } from "../ui/Text"



export const BasicCard = ({ children, title, subtitle, flex }: { children: any, title: string, subtitle: string, flex: any }) => {

    return (
        <Card
            sx={{
                padding: '0.5vw',
                flex: flex,
                flexWrap: "wrap",
                mb: "1.5vh",
                mr: "0.2vw",
                ml: "0.2vw"
            }}
        >
            <CardContent
                sx={{
                    padding: '0px',
                    ':last-child': { padding: '0px' },
                    display: 'flex',
                    alignItems: 'center',

                }}
            >
                <Grid width="100%">
                    <Grid container direction="column" mb="3vh">
                        <PrimaryText bold color={BLACK} gutterBottom mb="1vh">
                            {title}
                        </PrimaryText>
                        <SecondaryText>{subtitle}</SecondaryText>
                    </Grid>
                    <Grid>
                        {children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}