import React, { useEffect, useCallback, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { object, string } from 'yup'
import { PrimaryButton } from '../../../../../components/ui/Button'
import {
    EnhancedField,
    InputPasswordReveal,
    TextInput,
} from '../../../../../components/ui/Inputs'
import { type StepperData } from '../../../../../redux/stepper/stepperTypes'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { checkInvitationValidity } from '../../../../../redux/user/userSlice'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import {
    SecondaryText,
    SubTitle,
    Title,
} from '../../../../../components/ui/Text'
import Illustration from '../../../../../assets/hello.svg'
import { StepContentComponent } from '../stepper/Stepper'

export interface Inputs {
    type: string
    name: string
    label: string
    title?: string
}

interface FormValues {
    email: string
    password: string
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
    email: string
}

const PureSignUpAccountForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    return (
        <Grid>
            <Title paddingBottom="2vh">Créez votre compte</Title>
            <SubTitle>
                Vous êtes à quelques clics d'accéder à vos données...
            </SubTitle>
            <SecondaryText paddingBottom="4vh">
                (et oui, encore un mot de passe à retenir !)
            </SecondaryText>
            <Box display="flex" justifyContent="center" paddingBottom="4vh">
                <img
                    src={Illustration}
                    alt="Illustration"
                    style={{ width: '30vw', height: '20vh' }}
                />
            </Box>
            <Form>
                <Grid container direction="column">
                    {props.email ? (
                        <EnhancedField
                            type="email"
                            name="email"
                            label="Email"
                            margin="normal"
                            component={TextInput}
                            disabled
                            value={props.email}
                        />
                    ) : (
                        <EnhancedField
                            type="email"
                            name="email"
                            label="Email"
                            margin="normal"
                            component={TextInput}
                        />
                    )}
                    <InputPasswordReveal
                        name="password"
                        label="Créez votre mot de passe"
                    />
                    <Box mt={5} width="100%">
                        <PrimaryButton fullWidth type="submit">
                            Suivant
                        </PrimaryButton>
                    </Box>
                </Grid>
            </Form>
        </Grid>
    )
}

const ControlledSignUpAccountForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        email: props.initialValues.email,
        password: props.initialValues.password,
    }),
    enableReinitialize: true,
    validationSchema: () => {
        return object().shape({
            email: string()
                .email("L'email doit être une adresse valide")
                .required('Le champ Email est obligatoire'),
            password: string()
                .min(
                    8,
                    'Votre mot de passe doit contenir entre 8 et 72 caractères'
                )
                .max(
                    72,
                    'Votre mot de passe doit contenir entre 8 et 72 caractères'
                )
                .required('Le champ Mot de passe est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureSignUpAccountForm)

const SignUpAccount = ({
    getData,
    updateData,
    handleNext,
}: StepContentComponent) => {
    const initialValues = getData() as unknown as FormValues
    const [isLoading, setLoading] = useState<boolean>(true)

    const dispatch = useAppDispatch()
    const { email } = useAppSelector((state) => state.user.user)
    const _checkInvitationValidity = useCallback(
        (invitationId: string) =>
            dispatch(checkInvitationValidity(invitationId)),
        [dispatch]
    )
    const handleSubmit = (values: FormValues) => {
        updateData(values as unknown as StepperData)
        handleNext()
    }

    useEffect(() => {
        if (location.search) {
            const handleInvitationValidity = () => {
                const isValidate = _checkInvitationValidity(
                    location.search.slice(1)
                )
            }

            handleInvitationValidity()
        }
        setLoading(false)
    }, [])

    return (
        <Grid container direction="column">
            {isLoading ? (
                <Loading />
            ) : (
                <ControlledSignUpAccountForm
                    initialValues={initialValues}
                    email={email}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

export default SignUpAccount
