import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../redux/hooks'
import { PromoLayout } from '../layout/PromoLayout'
import { PromoPageV2 } from '../Pages/PromotionPage/page/PromoPageV2'

export const PromoPageFFR = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage period title="Promo360">
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <PromoLayout>
                <PromoPageV2 />
                {/* Old page to uncomment on alpha for demo purpose */}
                {/* <FsPromoPage
                    promoGraphTitle={
                        'Corrélation promo et vol moyen par utilisateur final'
                    }
                    sellInGraphTitle={
                        'Corrélation prix net et vol moyen par utilisateur final'
                    }
                    KPIsConfig={KPIsFFRConfig}
                    promoGraphCurveDataName={
                        'Volume sell-out par utilisateur final (kg)'
                    }
                    sellInGraphCurveDataName={'Volume sell-out par transaction'}
                    drillDownType={DrillDownType.PROMO}
                /> */}
            </PromoLayout>
        </PrivatePage>
    )
}
