export interface ProductTourState {
    productTour: ProductTour
}

export interface ProductTour {
    _id: string
    title: string
    usersId: string[]
    company: string
    dateOfExpiration: Date
}

export enum PRODUCT_TOUR_MESSAGES {
    OK = 'User click on OK',
    BE_CONCTACTED = 'User  click on Be Contacted',
}
