import React from 'react'
import { Helmet } from 'react-helmet'

export const HelmetProdWithCookies = ({
    firstname,
    lastname,
    brand,
    job,
    email,
    title,
}: {
    firstname: string
    lastname: string
    brand: string
    job: string
    email: string
    title: string
}) => {
    return (
        <Helmet>
            <title>{title}</title>

            {/* <!-- Start of HubSpot Embed Code --> */}
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js.hs-scripts.com/8639535.js"
            ></script>
            {/* <!-- End of HubSpot Embed Code --> */}

            {/* <!-- Hotjar Embed Code --> */}
            <script async defer>
                {`(function(h,o,t,j,a,r){
                    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings={hjid:3064007,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </script>
            <script>
                {` var userId = '${email}';
                window.hj('identify', userId,{
                    email: '${email}',
                    displayName: '${firstname} ${lastname}',
                    role: '${job}',
                    company: '${brand}'
                }
                );`}
            </script>
            {/* <!-- End of Hotjar Embed Code --> */}
        </Helmet>
    )
}

export const HelmetAlphaWithCookies = ({
    firstname,
    lastname,
    brand,
    job,
    email,
    title,
}: {
    firstname: string
    lastname: string
    brand: string
    job: string
    email: string
    title: string
}) => {
    return (
        <Helmet>
            <title>{title}</title>

            {/* <!-- Start of HubSpot Embed Code --> */}
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js.hs-scripts.com/8639535.js"
            ></script>
            {/* <!-- End of HubSpot Embed Code --> */}

            {/* <!-- Hotjar Embed Code for Alpha --> */}
            <script async defer>
                {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5081257,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </script>
            <script>
                {` var userId = '${email}';
                window.hj('identify', userId,{
                    email: '${email}',
                    displayName: '${firstname} ${lastname}',
                    role: '${job}',
                    company: '${brand}'
                }
                );`}
            </script>
            {/* <!-- End of Hotjar Embed Code --> */}
        </Helmet>
    )
}
