import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client, { setHeaders } from '../../api/config'
import { AppThunk } from '../store'
import { EnvType } from '../user/userTypes'
import {
    ActiveFsCrossFilters,
    ActiveFsFilters,
    ActiveRetailFilters,
    filtersState,
    FsCrossFilters,
    FsCrossViewFilters,
    FsFilters,
    FsViewFilters,
    RetailFilters,
    RetailViewFilters,
} from './filtersTypes'

const initialRetailFilters: ActiveRetailFilters = {
    startDate: null,
    endDate: null,
    products: [],
    stores: [],
    distributionChannels: [],
    min: "",
    max: ""
}

const initialFsFilters: ActiveFsFilters = {
    startDate: null,
    endDate: null,
    products: [],
    clients: [],
    regions: [],
    categories: [],
    min: "",
    max: ""
}

const initialFsCrossFilters: ActiveFsCrossFilters = {
    startDate: null,
    endDate: null,
    distributors: [],
    products: [],
    clients: [],
    min: "",
    max: ""
}

const initialState: filtersState = {
    retailFilters: null,
    activeRetailFilters: initialRetailFilters,
    activeRetailViewFilters: initialRetailFilters,
    resetRetailFilters: initialRetailFilters,
    retailViewFilters: initialRetailFilters,
    fsFilters: null,
    activeFsViewFilters: initialFsFilters,
    activeFsFilters: initialFsFilters,
    resetFSFilters: initialFsFilters,
    fsViewFilters: initialFsFilters,
    fsCrossViewFilters: initialFsCrossFilters,
    activeFsCrossViewFilters: initialFsCrossFilters,
    isFiltered: false,
    fsCrossFilters: null,
    activeFsCrossFilters: initialFsCrossFilters,
    nonAvailableDates: []
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setRetailFilters: (state, action: PayloadAction<RetailFilters>) => {
            state.retailFilters = action.payload
        },
        setActiveRetailFilters: (
            state,
            action: PayloadAction<ActiveRetailFilters>
        ) => {
            state.activeRetailFilters = action.payload
        },
        resetActiveRetailFilters: (state) => {
            state.activeRetailFilters = initialRetailFilters
        },
        setRetailViewFilters: (
            state,
            action: PayloadAction<RetailViewFilters>
        ) => {
            state.retailViewFilters = action.payload
        },
        setActiveRetailViewFilters: (
            state,
            action: PayloadAction<ActiveRetailFilters>
        ) => {
            state.activeRetailViewFilters = action.payload
        },
        resetRetailFilters: (state) => {
            state.activeRetailFilters = initialRetailFilters
        },
        trashRetailFilters: (state) => {
            state.activeRetailFilters = {
                startDate: state.activeRetailFilters
                    ? state.activeRetailFilters.startDate
                    : '',
                endDate: state.activeRetailFilters
                    ? state.activeRetailFilters.endDate
                    : '',
                products: [],
                stores: [],
                distributionChannels: [],
                min: "",
                max: "",
            }
        },
        setFsCrossFilters: (state, action: PayloadAction<FsCrossFilters>) => {
            state.fsCrossFilters = action.payload
        },
        setFsCrossViewFilters: (state, action: PayloadAction<FsCrossViewFilters>) => {
            state.fsCrossViewFilters = action.payload
        },
        setActiveFsCrossViewFilters: (
            state,
            action: PayloadAction<ActiveFsCrossFilters>
        ) => {
            state.activeFsCrossViewFilters = action.payload
        },
        setActiveFsCrossFilters: (
            state,
            action: PayloadAction<ActiveFsCrossFilters>
        ) => {
            state.activeFsCrossFilters = action.payload
        },
        resetActiveFsCrossFilters: (state) => {
            state.activeFsFilters = initialFsFilters
        },
        setFsFilters: (state, action: PayloadAction<FsFilters>) => {
            state.fsFilters = action.payload
        },
        setActiveFsFilters: (state, action: PayloadAction<ActiveFsFilters>) => {
            state.activeFsFilters = action.payload
        },
        resetActiveFsFilters: (state) => {
            state.activeFsFilters = initialFsFilters
        },
        setFsViewFilters: (state, action: PayloadAction<FsViewFilters>) => {
            state.fsViewFilters = action.payload
        },
        setActiveFsViewFilters: (
            state,
            action: PayloadAction<ActiveFsFilters>
        ) => {
            state.activeFsViewFilters = action.payload
        },
        setNonAvailableDates: (state, action: PayloadAction<string[]>) => {
            state.nonAvailableDates = action.payload
        },
        trashFsFilters: (state) => {
            state.activeFsFilters = {
                startDate: state.activeFsFilters
                    ? state.activeFsFilters.startDate
                    : '',
                endDate: state.activeFsFilters
                    ? state.activeFsFilters.endDate
                    : '',
                products: [],
                clients: [],
                regions: [],
                categories: [],
                min: "",
                max: "",
            }
        },
        trashFsPeriodFilter: (state) => {
            state.activeFsFilters = {
                startDate: state.fsFilters.startDate,
                endDate: state.fsFilters.endDate,
                products: state.activeFsFilters
                    ? state.activeFsFilters.products
                    : [],
                clients: state.activeFsFilters
                    ? state.activeFsFilters.clients
                    : [],
                regions: state.activeFsFilters
                    ? state.activeFsFilters.regions
                    : [],
                categories: state.activeFsFilters
                    ? state.activeFsFilters.categories
                    : [],
                min: "",
                max: "",
            }
        },
        trashRetailPeriodFilter: (state) => {
            state.activeRetailFilters = {
                startDate: state.retailFilters.startDate,
                endDate: state.retailFilters.endDate,
                products: state.activeRetailFilters
                    ? state.activeRetailFilters.products
                    : [],
                stores: state.activeRetailFilters
                    ? state.activeRetailFilters.stores
                    : [],
                distributionChannels: state.activeRetailFilters
                    ? state.activeRetailFilters.distributionChannels
                    : [],
                min: "",
                max: "",
            }
        },
        trashFsCrossPeriodFilters: (state) => {
            state.activeFsCrossFilters = {
                startDate: state.fsCrossFilters
                    ? state.fsCrossFilters.startDate
                    : '',
                endDate: state.fsCrossFilters
                    ? state.fsCrossFilters.endDate
                    : '',
                products: state.activeFsCrossFilters.products ? state.activeFsCrossFilters.products : [],
                clients: state.activeFsCrossFilters.clients ? state.activeFsCrossFilters.clients : [],
                distributors: state.activeFsCrossFilters.distributors ? state.activeFsCrossFilters.distributors : [],
                min: "",
                max: "",
            }
        },
        trashFsCrossFilters: (state) => {
            state.activeFsCrossFilters = {
                startDate: state.fsCrossFilters
                    ? state.fsCrossFilters.startDate
                    : '',
                endDate: state.fsCrossFilters
                    ? state.fsCrossFilters.endDate
                    : '',
                products: [],
                clients: [],
                distributors: [],
                min: "",
                max: "",
            }
        },
        resetFSFilters: (state) => {
            state.activeFsFilters = initialFsFilters
        },
        resetFSViewFilters: (state) => {
            state.activeFsViewFilters = initialFsFilters
        },
    },
})

export const {
    setRetailFilters,
    setFsCrossViewFilters,
    setActiveRetailFilters,
    resetActiveRetailFilters,
    setRetailViewFilters,
    setActiveRetailViewFilters,
    resetRetailFilters,
    trashRetailFilters,
    setFsFilters,
    setActiveFsFilters,
    resetActiveFsFilters,
    setFsCrossFilters,
    setActiveFsCrossFilters,
    resetActiveFsCrossFilters,
    trashFsFilters,
    resetFSFilters,
    setActiveFsCrossViewFilters,
    setActiveFsViewFilters,
    setFsViewFilters,
    resetFSViewFilters,
    trashFsCrossFilters,
    trashFsCrossPeriodFilters,
    trashFsPeriodFilter,
    trashRetailPeriodFilter,
    setNonAvailableDates
} = filtersSlice.actions
export default filtersSlice.reducer

export const fetchFilters =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        const { name: distributor, type } = getState().global.env
        try {
            if (localStorage.getItem("multiAccountCompanyId")) { setHeaders(null, localStorage.getItem("multiAccountCompanyId")) }

            const filters = await client
                .get(`/user/filters?distrib=${distributor}&type=${type}`)
                .then((response) => {
                    return response.data as FsFilters | RetailFilters
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            let startDate = ''

            dispatch(setNonAvailableDates(filters.nonAvailableDates))

            if (
                (filters.startDate < '2023-01' &&
                    filters.endDate < '2023-01') ||
                (filters.startDate > '2023-01' && filters.endDate > '2023-01')
            ) {
                startDate = filters.startDate
            } else {
                startDate = '2023-01'
            }
            if (type === EnvType.FOOD_SERVICE) {
                dispatch(
                    setFsFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        min: filters.min,
                        max: filters.max,
                        products: filters.products,
                        ...(filters.regions && { regions: filters.regions }),
                        ...(filters.clients && { clients: filters.clients }),
                        ...(filters.categories && {
                            categories: filters.categories,
                        }),
                    })
                )
                dispatch(
                    setActiveFsFilters({
                        startDate: startDate,
                        endDate: filters.endDate,
                        min: filters.min,
                        max: filters.max,
                        products: [],
                        clients: [],
                        regions: [],
                        categories: [],
                    })
                )
            } else if (type === EnvType.RETAIl) {
                dispatch(
                    setRetailFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        products: filters.products,
                        stores: filters.stores,
                        distributionChannels: filters.distributionChannels,
                        min: filters.min,
                        max: filters.max
                    })
                )
                dispatch(
                    setActiveRetailFilters({
                        startDate: startDate,
                        endDate: filters.endDate,
                        products: [],
                        stores: [],
                        distributionChannels: [],
                        min: filters.min,
                        max: filters.max
                    })
                )
            } else {
                dispatch(
                    setFsCrossFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        distributors: filters.distributors,
                        products: filters.products,
                        clients: filters.clients,
                        min: filters.min,
                        max: filters.max
                    })
                )
                dispatch(
                    setActiveFsCrossFilters({
                        startDate: startDate,
                        endDate: filters.endDate,
                        distributors: [],
                        products: [],
                        clients: [],
                        min: filters.min,
                        max: filters.max
                    })
                )
            }
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }
